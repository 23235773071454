<template>
  <footer class="website-footer">
    <!-- <div class="website-footer-icon-header">
      <img src="../images/OxiaLogoAllWhite.png" alt="Logo" class="website-footer-icon-header-logo"/>
    </div> -->
    <div class="website-footer-body">
      <div class="website-footer-body-content">
        <a type="button" @click="openMailTo" class="website-footer-body-content-item">Support@oxia.org</a>
        <router-link to="/terms-and-conditions" class="website-footer-body-content-item">Terms & Conditions /Privacy Policy</router-link>
        <p class="website-footer-body-content-item copy-oxia">&copy; 2023 OXIA DM</p>
        <!-- <button class="website-footer-body-content-item contact-us">
          <div class="os-icon icon-email" style="width: 20px; height: 20px; background-color: #fff; margin-right: 5px;"></div>
          <span>CONTACT US</span>
        </button> -->
      </div>
    </div>
  </footer>
</template>

<script setup>
const openMailTo = () => {
  window.location.href = "mailto:support@oxia.org";
};
</script>
