<template>
  <button class="contact-us floating-button" @click="openMailTo">
    <div class="contact-us-icon-container">
        <div class="os-icon icon-email"></div>
    </div>
    <span class="contact-us-text">CONTACT US</span>
  </button>
</template>

<script setup>
const openMailTo = () => {
  window.location.href = "mailto:support@oxia.org";
};
</script>