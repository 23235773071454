<template>
  <div class="os-application-wrapper" :class="{'mt-0' : !showTopBar}">
    <TopBar/>
    <slot />
    <contactUs />
    <TheFooter v-if="showFooter"/>
  </div> 
</template>

<script setup>

const router = useRouter();

const showTopBar = computed(() => {
  let currentRoute = router.currentRoute;
  if (currentRoute.value.name !== 'book-your-appointment') return true;
  return false;
})

const showFooter = computed(() => {
  let currentRoute = router.currentRoute;
  if (currentRoute.value.name !== 'book-your-appointment') return true;
  return false;
})

</script>

<style scoped></style>